var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"user-list"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v("Importation Publications - "+_vm._s(_vm.n))]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"depressed":"","color":"primary","loading":_vm.importing},on:{"click":_vm.startImportation}},[_vm._v(" Import ")]),(_vm.running)?_c('v-btn',{attrs:{"color":"danger","dark":"","small":"","depressed":""},on:{"click":_vm.stopImportation}},[_vm._v(" Stop ")]):_vm._e()],1),_c('v-container',[_c('v-row',{staticClass:"fill-height",attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"type":"number","label":"Counter","outlined":"","dense":""},model:{value:(_vm.counter),callback:function ($$v) {_vm.counter=$$v},expression:"counter"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Per page","outlined":"","dense":"","type":"number"},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"type":"number","label":"Total","outlined":"","dense":""},model:{value:(_vm.total),callback:function ($$v) {_vm.total=$$v},expression:"total"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('h1',[_vm._v("Colors")])]),_c('v-col',{attrs:{"cols":"2"}},[_c('v-autocomplete',{attrs:{"label":_vm.$t('Green'),"items":_vm.$store.state['app'].colors,"item-text":"color_name","item-value":"id","outlined":"","dense":"","hide-details":"auto","multiple":false,"clearable":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-item',{style:({'background-color': item.bg_color, 'color': item.txt_color })},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("  ")])],1)],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-item',{staticStyle:{"padding":"0px 4px"},style:({'background-color': item.bg_color, 'color': item.txt_color })},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("  ")])],1)],1)]}}]),model:{value:(_vm.green),callback:function ($$v) {_vm.green=$$v},expression:"green"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-autocomplete',{staticStyle:{"width":"150px"},attrs:{"label":_vm.$t('Red'),"items":_vm.$store.state['app'].colors,"item-text":"color_name","item-value":"id","outlined":"","dense":"","hide-details":"auto","multiple":false,"clearable":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-item',{style:({'background-color': item.bg_color, 'color': item.txt_color })},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("  ")])],1)],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-item',{staticStyle:{"padding":"0px 4px"},style:({'background-color': item.bg_color, 'color': item.txt_color })},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("  ")])],1)],1)]}}]),model:{value:(_vm.red),callback:function ($$v) {_vm.red=$$v},expression:"red"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-autocomplete',{staticStyle:{"width":"150px"},attrs:{"label":_vm.$t('Orange'),"items":_vm.$store.state['app'].colors,"item-text":"color_name","item-value":"id","outlined":"","dense":"","hide-details":"auto","multiple":false,"clearable":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-item',{style:({'background-color': item.bg_color, 'color': item.txt_color })},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("  ")])],1)],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-item',{staticStyle:{"padding":"0px 4px"},style:({'background-color': item.bg_color, 'color': item.txt_color })},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("  ")])],1)],1)]}}]),model:{value:(_vm.orange),callback:function ($$v) {_vm.orange=$$v},expression:"orange"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-autocomplete',{staticStyle:{"width":"150px"},attrs:{"label":_vm.$t('Yellow'),"items":_vm.$store.state['app'].colors,"item-text":"color_name","item-value":"id","outlined":"","dense":"","hide-details":"auto","multiple":false,"clearable":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-item',{style:({'background-color': item.bg_color, 'color': item.txt_color })},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("  ")])],1)],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-item',{staticStyle:{"padding":"0px 4px"},style:({'background-color': item.bg_color, 'color': item.txt_color })},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("  ")])],1)],1)]}}]),model:{value:(_vm.yellow),callback:function ($$v) {_vm.yellow=$$v},expression:"yellow"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-autocomplete',{staticStyle:{"width":"150px"},attrs:{"label":_vm.$t('White'),"items":_vm.$store.state['app'].colors,"item-text":"color_name","item-value":"id","outlined":"","dense":"","hide-details":"auto","multiple":false,"clearable":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-item',{style:({'background-color': item.bg_color, 'color': item.txt_color })},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("  ")])],1)],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-item',{staticStyle:{"padding":"0px 4px"},style:({'background-color': item.bg_color, 'color': item.txt_color })},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("  ")])],1)],1)]}}]),model:{value:(_vm.white),callback:function ($$v) {_vm.white=$$v},expression:"white"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-autocomplete',{staticStyle:{"width":"150px"},attrs:{"label":_vm.$t('Black'),"items":_vm.$store.state['app'].colors,"item-text":"color_name","item-value":"id","outlined":"","dense":"","hide-details":"auto","multiple":false,"clearable":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-item',{style:({'background-color': item.bg_color, 'color': item.txt_color })},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("  ")])],1)],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-item',{staticStyle:{"padding":"0px 4px"},style:({'background-color': item.bg_color, 'color': item.txt_color })},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("  ")])],1)],1)]}}]),model:{value:(_vm.black),callback:function ($$v) {_vm.black=$$v},expression:"black"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-autocomplete',{staticStyle:{"width":"150px"},attrs:{"label":_vm.$t('Cyan'),"items":_vm.$store.state['app'].colors,"item-text":"color_name","item-value":"id","outlined":"","dense":"","hide-details":"auto","multiple":false,"clearable":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-item',{style:({'background-color': item.bg_color, 'color': item.txt_color })},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("  ")])],1)],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-item',{staticStyle:{"padding":"0px 4px"},style:({'background-color': item.bg_color, 'color': item.txt_color })},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("  ")])],1)],1)]}}]),model:{value:(_vm.cyan),callback:function ($$v) {_vm.cyan=$$v},expression:"cyan"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.datas,"items-per-page":20}})],1)],1)],1)],1)],1)],1),_c('v-snackbar',{attrs:{"color":_vm.snackBarColor},model:{value:(_vm.isSnackbarBottomVisible),callback:function ($$v) {_vm.isSnackbarBottomVisible=$$v},expression:"isSnackbarBottomVisible"}},[_vm._v(" "+_vm._s(_vm.snackBarMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }