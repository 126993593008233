<template>
  <div id="user-list">
      <v-row justify="center">
              <v-col
                cols="12"
                md="12"
              >
                <v-card>
                  <v-card-title>
                    <span>Importation Communication - {{ n }}</span>
                    <v-spacer />
                    <v-btn
                      class="mr-2"
                      depressed
                      color="primary"
                      :loading="importing"
                      @click="startImportation"
                    >
                      Import
                    </v-btn>
                    <v-btn
                      v-if="running"
                      color="danger"
                      dark
                      small
                      depressed
                      @click="stopImportation"
                    >
                      Stop
                    </v-btn>
                  </v-card-title>
                  <v-container>
                    <v-row
                      class="fill-height"
                      justify="center"
                      align="center"
                    >
                      <v-col
                        cols="6"
                      >
                        <v-text-field
                          v-model="counter"
                          type="number"
                          label="Counter"
                          outlined
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="6"
                      >
                        <v-text-field
                          v-model="perPage"
                          label="Per page"
                          outlined
                          dense
                          type="number"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                      >
                        <v-text-field
                          v-model="total"
                          type="number"
                          label="Total"
                          outlined
                          dense
                        />
                      </v-col>

                      <v-col
                        cols="12"
                      >
                        <h1>Colors</h1>
                      </v-col>
                      <v-col
                        cols="2"
                      >
                        <v-autocomplete
                          v-model="green"
                          :label="$t('Green')"
                          :items="$store.state['app'].colors"
                          item-text="color_name"
                          item-value="id"
                          outlined
                          dense
                          hide-details="auto"
                          :multiple="false"
                          clearable
                        >
                          <template #item="{ item }">
                            <v-item :style="{'background-color': item.bg_color, 'color': item.txt_color }">
                              <v-list-item-content>
                                <v-list-item-title>
                                  <!--                            {{ item.color_name }}-->&nbsp;
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-item>
                          </template>
                          <template #selection="{ item }">
                            <v-item
                              :style="{'background-color': item.bg_color, 'color': item.txt_color }"
                              style="padding: 0px 4px;"
                            >
                              <v-list-item-content>
                                <v-list-item-title>
                                  <!--                            {{ item.color_name }}-->&nbsp;
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-item>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col
                        cols="2"
                      >
                        <v-autocomplete
                          v-model="red"
                          :label="$t('Red')"
                          :items="$store.state['app'].colors"
                          item-text="color_name"
                          item-value="id"
                          outlined
                          dense
                          hide-details="auto"
                          :multiple="false"
                          clearable
                          style="width: 150px"
                        >
                          <template #item="{ item }">
                            <v-item :style="{'background-color': item.bg_color, 'color': item.txt_color }">
                              <v-list-item-content>
                                <v-list-item-title>
                                  <!--                            {{ item.color_name }}-->&nbsp;
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-item>
                          </template>
                          <template #selection="{ item }">
                            <v-item
                              :style="{'background-color': item.bg_color, 'color': item.txt_color }"
                              style="padding: 0px 4px;"
                            >
                              <v-list-item-content>
                                <v-list-item-title>
                                  <!--                            {{ item.color_name }}-->&nbsp;
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-item>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col
                        cols="2"
                      >
                        <v-autocomplete
                          v-model="orange"
                          :label="$t('Orange')"
                          :items="$store.state['app'].colors"
                          item-text="color_name"
                          item-value="id"
                          outlined
                          dense
                          hide-details="auto"
                          :multiple="false"
                          clearable
                          style="width: 150px"
                        >
                          <template #item="{ item }">
                            <v-item :style="{'background-color': item.bg_color, 'color': item.txt_color }">
                              <v-list-item-content>
                                <v-list-item-title>
                                  <!--                            {{ item.color_name }}-->&nbsp;
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-item>
                          </template>
                          <template #selection="{ item }">
                            <v-item
                              :style="{'background-color': item.bg_color, 'color': item.txt_color }"
                              style="padding: 0px 4px;"
                            >
                              <v-list-item-content>
                                <v-list-item-title>
                                  <!--                            {{ item.color_name }}-->&nbsp;
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-item>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col
                        cols="2"
                      >
                        <v-autocomplete
                          v-model="yellow"
                          :label="$t('Yellow')"
                          :items="$store.state['app'].colors"
                          item-text="color_name"
                          item-value="id"
                          outlined
                          dense
                          hide-details="auto"
                          :multiple="false"
                          clearable
                          style="width: 150px"
                        >
                          <template #item="{ item }">
                            <v-item :style="{'background-color': item.bg_color, 'color': item.txt_color }">
                              <v-list-item-content>
                                <v-list-item-title>
                                  <!--                            {{ item.color_name }}-->&nbsp;
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-item>
                          </template>
                          <template #selection="{ item }">
                            <v-item
                              :style="{'background-color': item.bg_color, 'color': item.txt_color }"
                              style="padding: 0px 4px;"
                            >
                              <v-list-item-content>
                                <v-list-item-title>
                                  <!--                            {{ item.color_name }}-->&nbsp;
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-item>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col
                        cols="2"
                      >
                        <v-autocomplete
                          v-model="white"
                          :label="$t('White')"
                          :items="$store.state['app'].colors"
                          item-text="color_name"
                          item-value="id"
                          outlined
                          dense
                          hide-details="auto"
                          :multiple="false"
                          clearable
                          style="width: 150px"
                        >
                          <template #item="{ item }">
                            <v-item :style="{'background-color': item.bg_color, 'color': item.txt_color }">
                              <v-list-item-content>
                                <v-list-item-title>
                                  <!--                            {{ item.color_name }}-->&nbsp;
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-item>
                          </template>
                          <template #selection="{ item }">
                            <v-item
                              :style="{'background-color': item.bg_color, 'color': item.txt_color }"
                              style="padding: 0px 4px;"
                            >
                              <v-list-item-content>
                                <v-list-item-title>
                                  <!--                            {{ item.color_name }}-->&nbsp;
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-item>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col
                        cols="2"
                      >
                        <v-autocomplete
                          v-model="black"
                          :label="$t('Black')"
                          :items="$store.state['app'].colors"
                          item-text="color_name"
                          item-value="id"
                          outlined
                          dense
                          hide-details="auto"
                          :multiple="false"
                          clearable
                          style="width: 150px"
                        >
                          <template #item="{ item }">
                            <v-item :style="{'background-color': item.bg_color, 'color': item.txt_color }">
                              <v-list-item-content>
                                <v-list-item-title>
                                  <!--                            {{ item.color_name }}-->&nbsp;
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-item>
                          </template>
                          <template #selection="{ item }">
                            <v-item
                              :style="{'background-color': item.bg_color, 'color': item.txt_color }"
                              style="padding: 0px 4px;"
                            >
                              <v-list-item-content>
                                <v-list-item-title>
                                  <!--                            {{ item.color_name }}-->&nbsp;
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-item>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col
                        cols="2"
                      >
                        <v-autocomplete
                          v-model="cyan"
                          :label="$t('Cyan')"
                          :items="$store.state['app'].colors"
                          item-text="color_name"
                          item-value="id"
                          outlined
                          dense
                          hide-details="auto"
                          :multiple="false"
                          clearable
                          style="width: 150px"
                        >
                          <template #item="{ item }">
                            <v-item :style="{'background-color': item.bg_color, 'color': item.txt_color }">
                              <v-list-item-content>
                                <v-list-item-title>
                                  <!--                            {{ item.color_name }}-->&nbsp;
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-item>
                          </template>
                          <template #selection="{ item }">
                            <v-item
                              :style="{'background-color': item.bg_color, 'color': item.txt_color }"
                              style="padding: 0px 4px;"
                            >
                              <v-list-item-content>
                                <v-list-item-title>
                                  <!--                            {{ item.color_name }}-->&nbsp;
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-item>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col
                        cols="12"
                      >
                        <v-data-table
                          :headers="headers"
                          :items="datas"
                          :items-per-page="20"
                          class="elevation-1"
                        ></v-data-table>
                      </v-col>
                    </v-row>
                  </v-container>

                </v-card>
              </v-col>
            </v-row>
    <!-- configuration card -->

    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="snackBarColor"
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiClose,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
  mdiPencilOutline,
  mdiContentSaveOutline,
  mdiImage,
} from '@mdi/js'
import {
  computed, ref, watch, onUnmounted,
} from '@vue/composition-api'
import axios from '@axios'

// sidebar
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import userStoreModule from './itemStoreModule'

import controller from './Controller'

export default {
  components: {
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'configuration'
    const importing = ref(false)
    const running = ref(false)
    const counter = ref(0)
    const perPage = ref(1960)
    const n = ref(0)
    const datas = ref([])
    const total = ref(1960)
    const headers = ref([
      {
        text: 'Id',
        align: 'start',
        sortable: true,
        value: 'id',
      },
      {
        text: 'Duration',
        align: 'start',
        sortable: true,
        value: 'data_duration',
      },
      {
        text: 'Duration',
        align: 'start',
        sortable: true,
        value: 'data_duration',
      },
      {
        text: 'Collection',
        align: 'start',
        sortable: true,
        value: 'data_collection_duration',
      },
      {
        text: 'Publication',
        align: 'start',
        sortable: true,
        value: 'data_publication_duration',
      },
      {
        text: 'Publication1',
        align: 'start',
        sortable: true,
        value: 'data_publication1_duration',
      },
      {
        text: 'Total comments',
        align: 'start',
        sortable: true,
        value: 'data_comment_duration',
      },
      {
        text: 'Comment duration',
        align: 'start',
        sortable: true,
        value: 'data_comment1_duration',
      },
    ])
    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
    store.state.app.settings.manuscript_approuved_status = parseInt(store.state.app.settings.manuscript_approuved_status, 10) || null
    store.state.app.settings.publication_signed_status = parseInt(store.state.app.settings.publication_signed_status, 10) || null
    store.state.app.settings.publication_bat_status = parseInt(store.state.app.settings.publication_bat_status, 10) || null
    store.state.app.settings.publication_bod_status = parseInt(store.state.app.settings.publication_bod_status, 10) || null
    store.state.app.settings.white_color_id = parseInt(store.state.app.settings.white_color_id, 10) || null
    store.state.app.settings.green_color_id = parseInt(store.state.app.settings.green_color_id, 10) || null
    const {
      item,
      items,
      tableColumns,
      searchQuery,
      totalItems,
      loading,
      options,
      configurations,
      selectedRows,
      isFormActive,
      snackBarMessage,
      snackBarColor,
      isSnackbarBottomVisible,
      openDialog,
      deleteItem,
      onSubmit,
      onSubmitMultiple,
      loadingManuscript,
      loadingColor,
      loadingQuotation,
      onSubmitMultipleColors,
      onSubmitMultipleManuscript,
      onSubmitMultipleQuotation,
      confirmDelete,
      deleteDialog,
      fetchItems,
    } = controller()

    const green = ref(null)
    const orange = ref(null)
    const red = ref(null)
    const yellow = ref(null)
    const black = ref(null)
    const white = ref(null)
    const cyan = ref(null)

    const importData = () => {
      if (counter.value <= total.value && running.value) {
        n.value += 1
        axios.post('/importCommunication', {
          green: green.value,
          orange: orange.value,
          red: red.value,
          yellow: yellow.value,
          black: black.value,
          white: white.value,
          cyan: cyan.value,
          number_per_page: perPage.value,
          start_rows: counter.value,
        }).then(response => {
          counter.value = parseInt(counter.value) + parseInt(perPage.value)
          for (let i = 0; i < response.data.data.length; i++) {
            datas.value.push(response.data.data[i])
          }
          importData()
        }).catch(() => {
          importing.value = false
          running.value = false
        })
      } else {
        importing.value = false
      }
    }
    const startImportation = () => {
      running.value = true
      importing.value = true
      importData()
    }
    const stopImportation = () => {
      running.value = false
    }

    return {
      n,
      green,
      orange,
      red,
      yellow,
      black,
      white,
      cyan,
      startImportation,
      importing,
      counter,
      running,
      perPage,
      item,
      total,
      headers,
      stopImportation,
      datas,
      tableColumns,
      searchQuery,
      totalItems,
      loading,
      options,
      configurations,
      mdiContentSaveOutline,
      isFormActive,
      selectedRows,
      snackBarMessage,
      snackBarColor,
      isSnackbarBottomVisible,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      deleteDialog,
      confirmDelete,
      openDialog,
      deleteItem,
      avatarText,
      fetchItems,
      onSubmit,
      onSubmitMultiple,
      loadingManuscript,
      loadingQuotation,
      loadingColor,
      onSubmitMultipleColors,
      onSubmitMultipleManuscript,
      onSubmitMultipleQuotation,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiClose,
        mdiExportVariant,
        mdiAccountOutline,
        mdiPencilOutline,
        mdiContentSaveOutline,
        mdiImage,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
